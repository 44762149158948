import React from "react";
import men from "../../Assets/image/men.jpg";

const About3 = () => {
  return (
    <div className="about3">
      <div>
        <span className="teamtitle">Our Team</span>
      </div>

      <div className="teammember">
        <div className="member">
          <img className="ownerimg" src={men} alt="" />
          <br />
          <span className="teamtext">Vikas Khanna</span> <br />
          <span className="teamtext">Owner</span> <br />
          <span className="teamtext">Architect</span>
        </div>

        <div className="member">
          <img className="ownerimg" src={men} alt="" />
          <br />
          <span className="teamtext">Vikas Khanna</span> <br />
          <span className="teamtext">Owner</span> <br />
          <span className="teamtext">Architect</span>
        </div>

        <div className="member">
          <img className="ownerimg" src={men} alt="" />
          <br />
          <span className="teamtext">Vikas Khanna</span> <br />
          <span className="teamtext">Owner</span> <br />
          <span className="teamtext">Architect</span>
        </div>

        <div className="member">
          <img className="ownerimg" src={men} alt="" />
          <br />
          <span className="teamtext">Vikas Khanna</span> <br />
          <span className="teamtext">Owner</span> <br />
          <span className="teamtext">Architect</span>
        </div>
      </div>
    </div>
  );
};

export default About3;
