import React from "react";
import { Link, useLocation } from "react-router-dom";
import Logo from "../../Assets/image/logo1.png";
import HamBurger from "./HamBurger";
import "./Navbar.css";

function Navbar() {
  const location = useLocation();

  const { pathname } = location;

  const splitLocation = pathname.split("/");

  // const iconStyle = {
  //   size: "1.2rem",
  // };
  return (
    <>
      <div className="navContainer">
        <Link
          className={
            splitLocation[1] === "" ? "navTitles activeNav" : "navTitles"
          }
          to="/"
        >
          <i className="fa-solid fa-house "></i>
          <div> Home </div>
        </Link>
        <Link
          className={
            splitLocation[1] === "about" ? "navTitles activeNav" : "navTitles"
          }
          to="/about"
        >
          <i className="fa-solid fa-users "></i>
          <div> About </div>
        </Link>

        <div className="navLogo">
          <img src={Logo} alt="Logo" />
        </div>

        <Link
          className={
            splitLocation[1] === "design" ? "navTitles activeNav" : "navTitles"
          }
          to="/design"
        >
          <i className="fa-regular fa-object-group"></i>
          <div> Design </div>
        </Link>
        <Link
          className={
            splitLocation[1] === "contact" ? "navTitles activeNav" : "navTitles"
          }
          to="/contact"
        >
          <i className="fa-sharp fa-regular fa-id-card"></i>
          <div> Contact Us </div>
        </Link>
        <div className="hamBurger">
          <HamBurger />
        </div>
      </div>
    </>
  );
}

export default Navbar;
