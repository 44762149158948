import React from "react";
import house from "../../Assets/image/house.jpg";

function ProjectCard() {
  return (
    <>
      <div className="projcarddesign">
        <div>
          <img src={house}  width="100%" alt="" />
        </div>
        <br />
      </div>
    </>
  );
}

export default ProjectCard;
