import React from 'react'

const About5 = () => {
  return (
    <div className='about5'>
        <div className='offertitle'>
           <span >What we Offer</span>
        </div>
        <div className='about5btm'>
            <div className='about5box'>
                <p className='titleoffer'>Our  Services</p>
                <ul>
                  <li>Site Layouts</li>
                  <li>Floor Plans</li>
                  <li>Site Render Plans</li>
                  <li>Exterior Elevations 2D</li>
                  <li>Exterior Elevations 3D</li>
                  <li>Sections</li>
                  <li>Floor Internal Views</li>
                  <li>Room Internal Views</li>
                  <li>Doors Layout</li>
                  <li>Windows Layout</li>
                </ul>
            </div>
            <div className='about5box'>
              <p className='titleoffer'>Additional works</p>
              <ul>
                  <li>Site Layouts</li>
                  <li>Floor Plans</li>
                  <li>Site Render Plans</li>
                  <li>Exterior Elevations 2D</li>
                  <li>Exterior Elevations 3D</li>
                  <li>Sections</li>
                  <li>Floor Internal Views</li>
                  <li>Room Internal Views</li>
                  <li>Doors Layout</li>
                  <li>Windows Layout</li>
                </ul>
            </div>
            <div className='about5box'>
                <p className='titleoffer'>Technology and science</p>
                <ul>
                  <li>Site Layouts</li>
                  <li>Floor Plans</li>
                  <li>Site Render Plans</li>
                  <li>Exterior Elevations 2D</li>
                  <li>Exterior Elevations 3D</li>
                  <li>Sections</li>
                  <li>Floor Internal Views</li>
                  <li>Room Internal Views</li>
                  <li>Doors Layout</li>
                  <li>Windows Layout</li>
                </ul>
            </div>
        </div>
    
    </div>
  )
}

export default About5