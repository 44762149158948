import React from 'react'

const AboutMiddlebar = () => {
  return (
    <div className='middlebar'>
        <span>Our Process</span>
    </div>
  )
}

export default AboutMiddlebar