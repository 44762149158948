import React from "react";
import Swiperpanel from "./Swiperpanel";
import Bottom from "./Bottom";
import "./HomePage.css";
const Homepage = () => {
  return (
    <div>
      <Swiperpanel />
      <div className="middlebar">
        <span>Its Simpler Than you Think!</span>
      </div>
      <Bottom />
    </div>
  );
};

export default Homepage;
