import React from "react";
import img1 from "../../Assets/image/image1.jpg";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Pagination, Autoplay } from "swiper";
import "swiper/css";
import "swiper/css/pagination";

const Bottom = () => {
  var menu = [
    "Design Page",
    "Filter Design",
    "Select Design",
    "Make Payment",
    "View Design",
  ];
  const pagination = {
    el: ".swiper-pagination",
    clickable: true,
    renderBullet: function (index, className) {
      return (
        ' <span class="' +
        className +
        '">' +
        (index + 1) +
        '<span class="belowcircletxt">' +
        menu[index] +
        " </span></span>"
      );
    },
  };
  SwiperCore.use([Pagination, Autoplay]);
  return (
    <div className="bottom">
      <div className="circlebar">
        {/* <div className="circlediv">
          <div className="innercircle">
            <p>1</p>
          </div>
          <span className="belowcircletxt">Design Page</span>
        </div>
        <hr />
        <div className="circlediv">
          <div className="innercircle">
            <p>2</p>
          </div>
          <span className="belowcircletxt">Filter Design</span>
        </div>
        <div className="circlediv">
          <div className="innercircle">
            <p>3</p>
          </div>
          <span className="belowcircletxt">Select Design</span>
        </div>
        <div className="circlediv">
          <div className="innercircle">
            <p>4</p>
          </div>
          <span className="belowcircletxt">Make Payment</span>
        </div>
        <div className="circlediv">
          <div className="innercircle">
            <p>5</p>
          </div>
          <span className="belowcircletxt">View Design</span>
        </div> */}

        {/* <div className="btmimg">
        <img className="img-fluid" src={img1} alt="img1" />
      </div> */}
        <div className="swiper-pagination"></div>
        <Swiper
          modules={[Pagination]}
          spaceBetween={30}
          slidesPerView={1}
          // centeredSlides={true}
          pagination={pagination}
          autoplay={{
            delay: 5000,
            disableOnInteraction: false,
          }}
          loop={true}
          className="mySwiper"
        >
          <SwiperSlide>
            <img src={img1} alt="cscs" />
          </SwiperSlide>
          <SwiperSlide>
            <img src={img1} alt="cscs" />
          </SwiperSlide>
          <SwiperSlide>
            <img src={img1} alt="cscs" />
          </SwiperSlide>
          <SwiperSlide>
            <img src={img1} alt="cscs" />
          </SwiperSlide>
          <SwiperSlide>
            <img src={img1} alt="cscs" />
          </SwiperSlide>
        </Swiper>
      </div>
    </div>
  );
};

export default Bottom;
