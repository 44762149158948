import React from "react";
import logo2 from "../../Assets/image/logo2.jpg";
import "./About1.css";

const About1 = () => {
  return (
    <div>
      <div className="about1">
        <div className="imagebox">
          <img className="imagelogo" src={logo2} alt="" />
        </div>
        <div className="innbox">
          <div className="abouttitle">
            <span>About Us</span>
          </div>
          <div className="about1text">
            <p>
              Quality over quantity, so the saying goes. With so many concepts
              floating around the architectural profession, it can be difficult
              to keep up with all the ideas which you're expected to know. But
              in architecture and elsewhere, the most memorable ideas are often
              the ones that can be condensed textually: “form follows function,”
              “less is more,” “less is a bore.” Though slightly longer than
              three words, the following lists a selection of texts that don’t
              take too long to read, but impart long-lasting lessons, offering
              you the opportunity to fill gaps in your knowledge quickly and
              efficiently. Covering everything from loos to Adolf Loos, the
              public to the domestic, and color to phenomenology, read on for
              eight texts to place on your reading list
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About1;
