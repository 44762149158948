import React from "react";
import "./SideMenu.css";

function SideMenu() {
  return (
    <>
      <div className="sideMenu">
        <ul>
          <li>
            <i className="fa-regular fa-heart fa-2x"></i>
          </li>
          <li>
            <i className="fa-solid fa-cart-shopping fa-2x"></i>
          </li>
          <li>
            <i className="fa-regular fa-circle-user fa-2x"></i>
          </li>
        </ul>
      </div>
    </>
  );
}

export default SideMenu;
