import React from "react";
import { Link } from "react-router-dom";
import "./HamBurger.css";
import logo from "../../Assets/image/logo1.png";

function HamBurger() {
  function handleUncheck() {
    let ham = document.getElementById("ham");
    if (ham) {
      ham.checked = false;
    } else {
      ham.checked = true;
    }
  }
  return (
    <>
      <div className="hamContainer center">
        <input type="checkbox" name="ham" id="ham" />
        <label id="hamLabel" className="center" htmlFor="ham">
          <span></span>
          <span></span>
          <span></span>
          {/* <span></span> */}
        </label>
        <div className="hamOption">
          <ul className="center">
            <li className="center">
              <img src={logo} alt="logo" width={120} height={100} />
            </li>
            <li className="center">
              <Link to="/">
                <button className="navBtn" onClick={handleUncheck}>
                  Home
                </button>
              </Link>
            </li>
            <li className="center">
              <Link to="/about">
                <button className="navBtn" onClick={handleUncheck}>
                  About
                </button>
              </Link>
            </li>
            <li className="center">
              <Link to="/design">
                <button className="navBtn" onClick={handleUncheck}>
                  Design
                </button>
              </Link>
            </li>
            <li className="center">
              <Link to="/contact">
                <button className="navBtn" onClick={handleUncheck}>
                  Contact Us
                </button>
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
}

export default HamBurger;
