import React from "react";
import "./Footer.css";

const Footer = () => {
  return (
    <>
      <footer>
        <div className="footer-top">
          <h2>© 2023 vbi.com All Rights Reserved</h2>
        </div>
        <div className="phone">
          <i className="fa-solid fa-phone"></i>
          <h2>Ph no.: 6353839209</h2>
        </div>
        <div className="email">
          <i className="fa-solid fa-envelope"></i>
          <h2>Email id: utsavvasoya99@gmail.com</h2>
        </div>
      </footer>
    </>
  );
};

export default Footer;
