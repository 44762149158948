import React from "react";
import ProjectCard from "./ProjectCard";
import "./DesignPage.css";

const Designpage = () => {
  return (
    <>
      <div className="designpage">
        <div className="mainDesign">
          <div className="designcard">
            <div className="designtitle">
              <p>Residential Designs</p>
            </div>
            <div className="editbtn2">View all</div>
          </div>
          <div className="projectsdesign">
            <ProjectCard />
            <ProjectCard />
            <ProjectCard />
            <ProjectCard />
          </div>
        </div>
        <div className="mainDesign">
          <div className="designcard">
            <div className="designtitle">
              <p>Commercial Designs</p>
            </div>
            <div className="editbtn2">View all</div>
          </div>
          <div className="projectsdesign">
            <ProjectCard />
            <ProjectCard />
            <ProjectCard />
            <ProjectCard />
          </div>
        </div>
        <div className="mainDesign">
          <div className="designcard">
            <div className="designtitle">
              <p>Residential + Commercial Designs</p>
            </div>
            <div className="editbtn2">View all</div>
          </div>
          <div className="projectsdesign">
            <ProjectCard />
            <ProjectCard />
            <ProjectCard />
            <ProjectCard />
          </div>
        </div>
        <div className="mainDesign">
          <div className="designcard">
            <div className="designtitle">
              <p>Villa Designs</p>
            </div>
            <div className="editbtn2">View all</div>
          </div>
          <div className="projectsdesign">
            <ProjectCard />
            <ProjectCard />
            <ProjectCard />
            {/* <ProjectCard /> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default Designpage;
