import React from "react";
import Navbar from "./Components/Navbar/Navs";
import Homepage from "./Components/HomePage/Homepage";
import Aboutpage from "./Components/AboutPage/Aboutpage";
import Profilepage from "./Components/ProfilePage/Profilepage";
import Designpage from "./Components/DesignPage/Designpage";
import Footer from "./Components/Footer/Footer";
import Contact from "./Components/ContactPage/Contact";
import "./App.css";
import { Routes, Route } from "react-router-dom";
import SideMenu from "./Components/SideMenu/SideMenu";

const App = () => {
  return (
    <div>
      <div className="nav-cont">
        <Navbar />
      </div>
      <div className="mainApp">
        <Routes>
          <Route path="/" element={<Homepage />} />
          <Route path="/about" element={<Aboutpage />} />
          <Route path="/profile" element={<Profilepage />} />
          <Route path="/design" element={<Designpage />} />
          <Route path="/contact" element={<Contact />} />
        </Routes>
      </div>
      <Footer />
      <SideMenu />
    </div>
  );
};

export default App;
