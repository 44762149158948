import React from "react";

const About2 = () => {
  return (
    <div>
      <div className="about2">
        <div>
          <span className="goaltitle">Our Goal</span>
        </div>
        <div className="paragoal">
          <p>
            Quality over quantity, so the saying goes. With so many concepts
            floating around the architectural profession, it can be difficult to
            keep up with all the ideas which you're expected to know. But in
            architecture and elsewhere, the most memorable ideas are often the
            ones that can be condensed textually: “form follows function,” “less
            is more,” “less is a bore.” Though slightly longer than three words,
            the following lists a selection of texts that don’t take too long to
            read, but impart long-lasting lessons, offering you the opportunity
            to fill gaps in your knowledge quickly and efficiently. Covering
            everything from loos to Adolf Loos, the public to the domestic, and
            color to phenomenology, read on for eight texts to place on your
            reading list
          </p>
        </div>
      </div>
    </div>
  );
};

export default About2;
