import React from "react";
import house from "../../Assets/image/house.jpg";

const About7 = () => {
  return (
    <div className="about7">
      <div className="offertitle">
        <span>Our Unique Projects</span>
      </div>

      <div className="projects">
        <div className="projcard">
          <img src={house} className="sec1-image" width="100%" alt="" />

          <span>
            <b>Residential Design</b>
          </span>
          <span>Gruham - Vikas Khaana </span>
        </div>

        <div className="projcard">
          <img src={house} className="sec1-image" width="100%" alt="" />

          <span>
            <b>Commercial Design</b>
          </span>

          <span>Gruham - Vikas Khaana </span>
        </div>

        <div className="projcard">
          <img src={house} className="sec1-image" width="100%" alt="" />

          <span>
            <b>Residential Design</b>
          </span>

          <span>Gruham - Vikas Khaana </span>
        </div>

        <div className="projcard">
          <img src={house} className="sec1-image" width="100%" alt="" />

          <span>
            <b>Residential Design</b>
          </span>

          <span>Gruham - Vikas Khaana </span>
        </div>
      </div>
    </div>
  );
};

export default About7;
