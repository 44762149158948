import React from 'react'
import About1 from './About1'
import About2 from './About2'
import About3 from './About3'
import About4 from './About4'
import About5 from './About5'
import About6 from './About6'
import About7 from './About7'
import AboutMiddlebar from './AboutMiddlebar'

const Aboutpage = () => {
  return (
    <div>
        <About1/>
        <About2/>
        <About3/>
        <AboutMiddlebar/>
        <About4/>
        <About5/>
        <About6/>
        <About7/>
    </div>
  )
}

export default Aboutpage