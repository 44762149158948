import React from 'react'

const About6 = () => {
  return (
    <div className='about6'>
      <div className='offertitle'>
        <span >What Makes us Better</span>
     </div>
      <div>
      <table>
      <thead>
        <tr>
          <th></th>
          <th>Make My Ghar</th>
          <th>Other Architects</th>
        
        </tr>
      </thead>
      <tbody>
        <tr>
          <td data-column="First Name"> <b>Price</b> </td>
          <td data-column="Last Name">No hidden cost, Price match guarantee.Lowest price in the market</td>
          <td data-column="Job Title">Cost varies before and after finalisation
          Takes double the amount of the actual service</td>
         
        </tr>
        <tr>
          <td data-column="First Name"><b>Design Options</b> </td>
          <td data-column="Last Name">Multiple design options of make my Ghar and other existing architects
          . Per site there are 50 - 100 options</td>
          <td data-column="Job Title">You do not get other architect options for comparison Max 2-3 opts provided per site</td>
        
        </tr>
        <tr>
          <td data-column="First Name"><b>Timelines</b></td>
          <td data-column="Last Name">Fractions of seconds to get the whole product</td>
          <td data-column="Job Title">Takes about 2 weeks - 6 months of time to get the whole product</td>
        
        </tr>
        <tr>
          <td data-column="First Name"><b>Number of Services</b> </td>
          <td data-column="Last Name">Unlimited number of changes per call</td>
          <td data-column="Job Title">Unlimited no. of changes</td>
        
        </tr>
        <tr>
          <td data-column="First Name"><b>Quality</b> </td>
          <td data-column="Last Name">Council of architects which checks the design</td>
          <td data-column="Job Title">Only the principal architect check the design</td>
         
        </tr>
      </tbody>
    </table>
      </div>
    </div>
  )
}

export default About6