import React from "react";
import house from "../../Assets/image/house.jpg";

const Section1 = ({ bgcolor }) => {
  return (
    <>
      <div className="main-section" style={{ backgroundColor: bgcolor }}>
        <div className="section1">
          <div className="section1-title1">
            <h1>Gruham</h1>
            <h2>Ar Vikas Khanna</h2>

            <div className="sec1-sidebox">
              <div>
                <span>Site Dimension :</span>
                <br />
                <span>Area :</span>
                <br />
                <span>Specification :</span>
                <br />
                <span>GF : 3BHK</span>
                <br />
                <span>1F : Duplex</span>
              </div>

              <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit.
                Voluptates expedita dolorum suscipit magni, quod quos, eligendi
                accusantium repudiandae autem eum odit libero porro explicabo
                commodi, eveniet dicta voluptate quibusdam neque minus? Vel
                eaque soluta animi libero voluptatum commodi eum quia culpa
                magnam eos. Vitae quibusdam ipsa molestiae, modi praesentium
                doloribus?
              </p>
            </div>
          </div>
          <div className="section1-title2">
            <h1>Residential Design</h1>

            <div className="sec1-image">
              <img src={house} alt="house" />
              <img src={house} alt="house" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Section1;
