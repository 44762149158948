import Section1 from "./Section1";

import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation } from "swiper";
import React from "react";
import "swiper/css";

import "swiper/css/autoplay";

const Swiperpanel = () => {
  return (
    <Swiper
      spaceBetween={30}
      slidesPerView={1}
      centeredSlides={true}
      autoplay={{
        delay: 5000,
        disableOnInteraction: false,
      }}
      loop={true}
      navigation={true}
      modules={[Autoplay, Navigation]}
    >
      <SwiperSlide>
        <Section1 bgcolor={"#F87872"} />
      </SwiperSlide>
      <SwiperSlide>
        <Section1 bgcolor={"#BEB4A9"} />
      </SwiperSlide>
      <SwiperSlide>
        <Section1 bgcolor={"#E8BD72"} />
      </SwiperSlide>
      <SwiperSlide>
        <Section1 bgcolor={"#9EBEAB"} />
      </SwiperSlide>
      <SwiperSlide>
        <Section1 bgcolor={"#C6A4CC"} />
      </SwiperSlide>
    </Swiper>
  );
};

export default Swiperpanel;
