import React from 'react'

const Profilepage = () => {
  return (
    <div>

    <div className='profile_container'>
        <div>
             <img src="../image/men.jpg" className='profileimg'   alt="" />
        </div>
        <div className='profiletitle'><p>Mr Vikas Khanna</p></div>
        <div className='profileemail'><p>utsavvasoya99@gmail.com</p></div>
        <br />
        <div className='editbtn'>Edit Profile</div>
    </div>
    
    </div>
  )
}

export default Profilepage